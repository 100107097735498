.map-main-container {
    width: 100%;
    height: 100%;
}

.map-main-container.pin-input {
    cursor: crosshair !important;
}

.map-main-container canvas {
    overflow: clip;
}