body {
  margin: 0;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body.dark {
  scrollbar-color: #1976d2 #222222b3;
  scrollbar-width: thin;
}

html,
body.light {
  scrollbar-color: #90caf9 #ffffffb3;
  scrollbar-width: thin;
}

body.dark ::-webkit-scrollbar {
  height: 8px;
  width: 4px;
  background: #222222b3;
}

body.dark ::-webkit-scrollbar-corner {
  background: #222222b3;
}

body.light ::-webkit-scrollbar {
  height: 8px;
  width: 4px;
  background: #ffffffb3;
}

body.light ::-webkit-scrollbar-corner {
  background: #ffffffb3;
}

body.dark ::-webkit-scrollbar-thumb {
  background: #1976d2;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px #00000096;
}

body.light ::-webkit-scrollbar-thumb {
  background: #90caf9;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px #00000096;
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.ellipsis-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chart {
  display: initial;
}

.graph-tree .last-entity ul:last-child {
  display: none;
}

canvas.chart {
  cursor: pointer;
}

.severity-CRITICAL {
  color: red;
}

.severity-MAJOR {
  color: orange;
}

.severity-MINOR {
  color: yellow;
}

.severity-WARNING {
  color: purple;
}

.severity-INTERMEDIATE {
  color: green;
}