img.logo-image-locations{
    margin: 0% !important;
    padding: 0% !important;
    border-radius: 100%;
    width: 24px;
    height: 24px;
  }
  .cluster-logo.pill{
    margin: 0% !important;
    padding: 0% !important;
    border-width: 1.4px !important;
  }
  .icon.cluster {
    margin-top: 0 !important;
    margin-left: 0 !important;
    width: 8px !important;
    height: 8px !important;
  }
  .cluster.pill {
    color: var(--text-black);
    background-color: var(--layer-light-three);
    border-width: 1.4px !important;
    font-family: "Ericsson Hilda", Helvetica, Arial, sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
  
  .measure-point-number {
    background-color: inherit !important;
  }
  
  .card-content {
    margin-top: 16px;
  }
  .card-status-indexes {
    margin-top: 16px;
  }